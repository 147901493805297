export const colors = {
  primaryColor: "#7546c9",
  errorColor: "#ff4d4f",
  warningColor: "#faad14",
  successColor: "#52c41a",
  infoColor: "#1890ff",
  grayColor: "#AAAAAA",
};
export const extentColors = {
  primaryExtendColor: "#E0C6FD",
  infoExtendColor: "#E5EAFC",
  warningExtendColor: "#FFEB39",
};
export const textColors = {
  middleColor: "#777777",
};
// 原始值
// {
//    primaryColor: "#25b864",
//    errorColor: "#ff4d4f",
//    warningColor: "#faad14",
//    successColor: "#52c41a",
//    infoColor: "#1890ff",
// }