// stores/counter.js
import { defineStore } from 'pinia'
import cache from './cache'
const sessionKey = '_insurance_client_session'

export const useSessionStore = defineStore('session', {
  state: () => {
    return {
        currentMember: null,
    }
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    signIn(member) {
      cache.set(sessionKey, member)
      this.currentMember = member
    },
    signOut(callback){
      this.currentMember = null
      cache.remove(sessionKey)
      callback && callback()
    },
    isSignIn(){
      // this.currentMember = {id: 1, email: '1069888988@qq.com'};
      // return true;
      if(!this.currentMember) {
        this.currentMember = cache.get(sessionKey) || null
      }
      return this.currentMember !== null
    }
  },
})