import { createRouter, createWebHashHistory } from "vue-router"
// RouterTab 内置路由
import { useSessionStore } from './stores/session'
import {
  UserOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  ReconciliationOutlined,
  SecurityScanOutlined,
  FilterOutlined,
  ReadOutlined,
  TransactionOutlined,
  SettingOutlined
} from '@ant-design/icons-vue'
// 异步加载页面
const importPage = view => () => import(`./pages/${view}.vue`)


const routes = [
  { path: '/login', name: 'login', component: importPage('login'), meta: {
    fullScreen: true
  }},
  {
    path: '/',
    component: importPage('layout'),
    children: [
      {
        path: '/',
        name: 'home',
        component: importPage('home'),
        meta: {
          title: '数据面板',
          icon:   DashboardOutlined,
          closable: false,
          menu: true
        }
      },
      {
        path:'tasks',
        name: 'tasks',
        component: importPage('tasks'),
        meta: {
          title: '任务提示',
          icon: ReconciliationOutlined,
          menu: true,
          closable: true
        }
      },
      {
        path:'scan',
        name: 'scan',
        component: importPage('scan'),
        meta: {
          title: '问题扫描',
          icon: SecurityScanOutlined,
          menu: true,
          closable: true
        }
      },
      {
        path:'rules',
        name: 'rules',
        component: importPage('rules'),
        meta: {
          title: '规则设置',
          icon: FilterOutlined,
          menu: true,
          closable: true
        }
      },
      {
        path:'knowledges',
        name: 'knowledges',
        component: importPage('knowledges'),
        meta: {
          title: '知识库',
          icon: ReadOutlined,
          menu: true,
          closable: true
        }
      },
      {
        path:'bills',
        name: 'bills',
        component: importPage('bills'),
        meta: {
          title: '医保对账',
          icon: TransactionOutlined,
          menu: true,
          closable: true
        }
      },
      {
        path:'setting',
        name: 'setting',
        component: importPage('settings'),
        meta: {
          title: '设置',
          icon: SettingOutlined,
          menu: true,
          closable: true
        }
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to,from, next) => {
  const sessionStore = useSessionStore()
  if (to.name == "login") {
    next();
  } else if(sessionStore.isSignIn()) {
    next()
  } else {
    router.replace({name: 'login'})
  }
})

export default router
