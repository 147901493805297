import { createApp } from 'vue'
import App from '../client/app.vue'
import Router from '../client/router'
import {ApiPlugin} from '../client/libs/api'
import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.less'
import "ant-design-vue/dist/antd.variable.min.css"
import "../client/styles/index.less"
import { createPinia } from 'pinia'
// import TMap from '@map-component/vue-tmap'

const pinia = createPinia()
const  app = createApp(App)

app
.use(Router)
.use(Antd)
.use(pinia)
// .use(TMap)
.use(ApiPlugin)
.mount('#app')