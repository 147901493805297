<template>
<a-config-provider :locale="zhCN">
  <router-view/>
</a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import {defineComponent, inject, reactive } from 'vue'
import { ConfigProvider } from 'ant-design-vue'
import { colors } from "./libs/colors";

const colorState = reactive(colors)

ConfigProvider.config({
    theme: colorState
})

export default defineComponent({
    components: {

    },
    setup(props) {
      return {
        zhCN
      }
    }
})
</script>