import axios from 'axios'
import dayjs from 'dayjs'
import qs from 'qs'
import axiosRetry from 'axios-retry'
import * as jose from 'jose'
import { inject } from 'vue'
import { message } from 'ant-design-vue'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')

let accessToken = {};
const jwtKey = window._token();

let unauthorizedHandler = (resp) => {
  alert("登录授权已过期，请您重新登录");
  //window.location = '/welcome';
  // window.location = '/cmc/home';
}

const getAccessToken = async function (refresh) {
  if (!refresh && accessToken.token && accessToken.expiredAt && dayjs().valueOf() < accessToken.expiredAt) {
    return accessToken;
  }
  try {
    let expiredAt = dayjs().valueOf() + 24 * 60 * 60 * 1000
    let token = await new jose.SignJWT({ expired_at: expiredAt })
      .setProtectedHeader({ alg: 'HS256' })
      .sign(Uint8Array.from(jwtKey, (c) => c.charCodeAt(0)))
    accessToken = {
      token,
      expiredAt
    }
    console.log('%c Get new access token, token will expire at:' + dayjs.unix(accessToken.expiredAt / 1000).format(), 'color:green');
    return accessToken;
  } catch (err) {
    alert('获取 access token 出错，请刷新页面再试');
    console.log('getAccessToken Error: --->', err);
    return null;
  }
}

// 获取请求key
function getReqKey(config) {
  // 请求方式、请求地址、请求参数生成的字符串来作为是否重复请求的依据
  const { method, url} = config; // 解构出来这些参数
  // GET ---> params  POST ---> data
  const requestKey = [method, url].join('&');
  return requestKey;
}

// 取消重复请求
function removeReqKey(key) {
  if(pendingRequest.has(key)) {
      const cancelToken = pendingRequest.get(key);
      cancelToken(key); // 取消之前发送的请求
      pendingRequest.delete(key); // 请求对象中删除requestKey
  }
}

const pendingRequest = new Map(); // 请求对象
const CancelToken = axios.CancelToken;

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

const instance = axios.create({
  timeout: 120000,
  headers: {
    'Access-Token': accessToken.token,
  },
  paramsSerializer: {
    serialize: function (params) {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  },
});

instance.interceptors.request.use(async config => {
  let token = await getAccessToken();
  config.headers['Access-Token'] = token.token;

  // 获取请求key
  let requestKey = getReqKey(config);

  // 判断是否是重复请求
  if (pendingRequest.has(requestKey)) { // 是重复请求
    removeReqKey(requestKey); // 取消
  } else {
    // 设置cancelToken
    const t = new CancelToken(function executor(cancel) {
      pendingRequest.set(requestKey, cancel); // 设置
    })
    config.cancelToken = t;
  }

  return config;
});
instance.interceptors.response.use(function (response) {
  // console.log("==== response success ==>", response);
  // 请求对象中删除requestKey
  let requestKey = getReqKey(response.config);
  removeReqKey(requestKey);
  return response;
}, async function (error) {
  // console.log("==== response error ==>", error);
  // console.info(error.response.data);
  // console.info(error.response.status);
  if (error.response) {
    if (error.response.status >= 500) {
      message.error('服务器开小差了(code:' + error.response.status + ')，稍后再试吧')
    } else if (error.response.status == 401) {
      unauthorizedHandler && unauthorizedHandler(error.response)
    } else if (error.response.status == 403) {
      if (error.response.data.message == 'Token required') {
        // console.log('Token expired refresh !');
        let token = await getAccessToken(true);
        // console.log(token);
      } else {
        message.error('对不起，您无权访问！');
      }
    }
  }
  // 请求对象中删除requestKey
  let requestKey = getReqKey(response.config);
  removeReqKey(requestKey);
  return Promise.reject(error);
});

axiosRetry(instance, {
  retries: 2, retryCondition: (e) => {
    if (e.response && e.response.status == 403 && error.response.data.message == 'Token required') {
      return true;
    }
    return false;
  }
});


const injectionKey = Symbol('api')
export const useApi = () => inject(injectionKey)

// export default instance;
export const ApiPlugin = {
  install(app) {
    app.config.globalProperties.$api = instance
    app.provide(injectionKey, instance)
    if (app.config.globalProperties.$router) {
      unauthorizedHandler = (resp) => {
        const router = app.config.globalProperties.$router;
        router.replace({ name: 'login' })
      }
    }
  }
}